import React from 'react';
import './css/index.scss';
import { Oval } from 'react-loader-spinner';
import { COLORS } from '../../helpers/constants';

function MyLoading({
  height = 20,
  width = 20,
  strokeWidth = 2,
  className,
  visible,
  color = COLORS.primary,
  secondaryColor,
}) {
  return (
    <Oval
      height={height}
      width={width}
      color={color}
      wrapperClass={className}
      visible={visible}
      ariaLabel="oval-loading"
      secondaryColor={secondaryColor || color}
      strokeWidth={strokeWidth}
      strokeWidthSecondary={strokeWidth}
    />
  );
}

export default MyLoading;
