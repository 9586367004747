import { combineReducers } from 'redux';
import account from './account';
import organizations from './organizations';
import users from './users';
import admins from './admins';
import staticData from './staticData';
import reports from './reports';
import categories from './categories';

const rootReducer = combineReducers({
  account,
  organizations,
  users,
  admins,
  staticData,
  reports,
  categories,
});

export default rootReducer;
