import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../Api';
import {
  GET_ADMIN_CATEGORIES_FAIL,
  GET_ADMIN_CATEGORIES_REQUEST,
  GET_ADMIN_CATEGORIES_SUCCESS,
  GET_ADMIN_SUB_CATEGORIES_FAIL,
  GET_ADMIN_SUB_CATEGORIES_REQUEST,
  GET_ADMIN_SUB_CATEGORIES_SUCCESS,
} from '../actions/categories';

export default function* watcher() {
  yield takeLatest(GET_ADMIN_CATEGORIES_REQUEST, getAdminCategories);
  yield takeLatest(GET_ADMIN_SUB_CATEGORIES_REQUEST, getAdminSubCategories);
}

function* getAdminCategories(action) {
  try {
    const { data } = yield call(Api.getAdminCategories, action.payload);

    yield put({
      type: GET_ADMIN_CATEGORIES_SUCCESS,
      payload: data.categories,
    });
  } catch (error) {
    console.warn(error, 'while getting admin categories');
    yield put({
      type: GET_ADMIN_CATEGORIES_FAIL,
      payload: error,
    });
  }
}

function* getAdminSubCategories(action) {
  try {
    const { data } = yield call(Api.getAdminSubCategories, action.payload);

    yield put({
      type: GET_ADMIN_SUB_CATEGORIES_SUCCESS,
      payload: data.sub_categories,
    });
  } catch (error) {
    console.warn(error, 'while getting admin subcategories');
    yield put({
      type: GET_ADMIN_SUB_CATEGORIES_FAIL,
      payload: error,
    });
  }
}
