import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../css/index.module.scss';
import MyModal from '../../../components/MyModal';
import { CATEGORIES_MODAL_STATES } from '../constants';
import MyButton from '../../../components/MyButton';
import Api from '../../../Api';
import sendRequest from '../../../helpers/sendRequest';
import SearchInput from '../../../components/SearchInput';
import useInputState from '../../../helpers/hooks/useInputState';
import {
  createAdminCategory,
  createAdminSubCategory,
  deleteAdminCategory, deleteAdminSubCategory,
  updateAdminCategory, updateAdminSubCategory,
} from '../../../store/actions/categories';

function CategoriesModal({
  modalState,
  setModalState,

  categoryToInteract,
  subCategoryToInteract,
  selectedCategory,
  setSelectedCategory,
}) {
  const dispatch = useDispatch();
  const { countries } = useSelector((store) => store.staticData);

  const [name, setName, nameError, setNameError] = useInputState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addCategory = async () => {
    if (name?.length < 2) {
      return setNameError('Минимум 2 символа');
    }

    const data = await sendRequest({
      request: Api.createAdminCategory,
      payload: { name },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(createAdminCategory(data.category));
      closeModal();
    } else {
      alert(data?.message);
    }
  };

  const renameCategory = async () => {
    if (name?.length < 2) {
      return setNameError('Минимум 2 символа');
    }

    const data = await sendRequest({
      request: Api.renameAdminCategory,
      payload: { id: categoryToInteract._id, name },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateAdminCategory(data.category));
      if (selectedCategory?._id === data.category?._id) setSelectedCategory(data.category);
      closeModal();
    } else {
      alert(data?.message);
    }
  };

  const deleteCategory = async () => {
    const data = await sendRequest({
      request: Api.deleteAdminCategory,
      payload: { id: categoryToInteract._id },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(deleteAdminCategory(data.category));
      if (selectedCategory?._id === data.category?._id) setSelectedCategory(null);
      closeModal();
    }
  };

  const addSubCategory = async () => {
    if (name?.length < 2) {
      return setNameError('Минимум 2 символа');
    }

    const data = await sendRequest({
      request: Api.createAdminSubCategory,
      payload: { name, category_id: selectedCategory?._id },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(createAdminSubCategory(data.sub_category));
      closeModal();
    } else {
      alert(data?.message);
    }
  };

  const renameSubCategory = async () => {
    if (name?.length < 2) {
      return setNameError('Минимум 2 символа');
    }

    const data = await sendRequest({
      request: Api.renameAdminSubCategory,
      payload: { id: subCategoryToInteract._id, name },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateAdminSubCategory(data.sub_category));
      closeModal();
    } else {
      alert(data?.message);
    }
  };

  const deleteSubCategory = async () => {
    const data = await sendRequest({
      request: Api.deleteAdminSubCategory,
      payload: { id: subCategoryToInteract._id },
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(deleteAdminSubCategory(data.sub_category));
      closeModal();
    }
  };

  const closeModal = () => {
    if (loading) {
      return;
    }

    setIsModalOpen(false);
    setTimeout(() => {
      setModalState('');
      setName('', true);
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    setIsModalOpen(Boolean(modalState));
    if (modalState === CATEGORIES_MODAL_STATES.renameCategory) {
      setName(categoryToInteract.name, true);
    } else if (modalState === CATEGORIES_MODAL_STATES.renameSubCategory) {
      setName(subCategoryToInteract.name, true);
    }
  }, [modalState]);

  return (
    <MyModal
      isOpen={isModalOpen}
      closeFunction={closeModal}
    >
      <div className={styles.modal}>
        {modalState === CATEGORIES_MODAL_STATES.newCategory ? (
          <>
            <p className={styles.title}>Новая категория</p>
            <p className={styles.info}>
              Введите название для новой категории.
            </p>

            <div className={styles.input}>
              <SearchInput
                withLeftIcon={false}
                value={name}
                onChange={setName}
                placeholder="Название"
                error={nameError}
              />
            </div>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Добавить"
                loading={loading}
                onClick={addCategory}
              />
            </div>
          </>
        ) : modalState === CATEGORIES_MODAL_STATES.renameCategory ? (
          <>
            <p className={styles.title}>Переименовать категорию</p>
            <p className={styles.info}>
              Введите новое название для категории.
            </p>

            <div className={styles.input}>
              <SearchInput
                withLeftIcon={false}
                value={name}
                onChange={setName}
                placeholder="Название"
                error={nameError}
              />
            </div>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Сохранить"
                loading={loading}
                onClick={renameCategory}
              />
            </div>
          </>
        ) : modalState === CATEGORIES_MODAL_STATES.deleteCategory ? (
          <>
            <p className={styles.title}>Удалить?</p>
            <p className={styles.info}>
              Вы уверены что хотите удалить данную категорию (
              {categoryToInteract.name}
              )?
            </p>
            <p className={styles.info}>
              Категория не будет полностью удалена из базы, а будет скрыта.
            </p>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Удалить"
                loading={loading}
                onClick={deleteCategory}
              />
            </div>
          </>
        ) : modalState === CATEGORIES_MODAL_STATES.newSubCategory ? (
          <>
            <p className={styles.title}>Новая подкатегория</p>
            <p className={styles.info}>
              Введите название для новой подкатегории.
            </p>

            <div className={styles.input}>
              <SearchInput
                withLeftIcon={false}
                value={name}
                onChange={setName}
                placeholder="Название"
                error={nameError}
              />
            </div>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Добавить"
                loading={loading}
                onClick={addSubCategory}
              />
            </div>
          </>
        ) : modalState === CATEGORIES_MODAL_STATES.renameSubCategory ? (
          <>
            <p className={styles.title}>Переименовать подкатегорию</p>
            <p className={styles.info}>
              Введите новое название для подкатегории.
            </p>

            <div className={styles.input}>
              <SearchInput
                withLeftIcon={false}
                value={name}
                onChange={setName}
                placeholder="Название"
                error={nameError}
              />
            </div>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Сохранить"
                loading={loading}
                onClick={renameSubCategory}
              />
            </div>
          </>
        ) : modalState === CATEGORIES_MODAL_STATES.deleteSubCategory ? (
          <>
            <p className={styles.title}>Удалить?</p>
            <p className={styles.info}>
              Вы уверены что хотите удалить данную подкатегорию (
              {subCategoryToInteract.name}
              )?
            </p>
            <p className={styles.info}>
              Подкатегория не будет полностью удалена из базы, а будет скрыта.
            </p>

            <div className={styles.buttonsRow}>
              <MyButton
                text="Отмена"
                secondary
                disabled={loading}
                onClick={closeModal}
              />
              <MyButton
                text="Удалить"
                loading={loading}
                onClick={deleteSubCategory}
              />
            </div>
          </>
        ) : null}
      </div>
    </MyModal>
  );
}

export default CategoriesModal;
