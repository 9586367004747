export const GET_ADMIN_CATEGORIES_REQUEST = 'GET_ADMIN_CATEGORIES_REQUEST';
export const GET_ADMIN_CATEGORIES_SUCCESS = 'GET_ADMIN_CATEGORIES_SUCCESS';
export const GET_ADMIN_CATEGORIES_FAIL = 'GET_ADMIN_CATEGORIES_FAIL';

export function getAdminCategoriesRequest(payload = {}) {
  return {
    type: GET_ADMIN_CATEGORIES_REQUEST,
    payload,
  };
}

export const GET_ADMIN_SUB_CATEGORIES_REQUEST = 'GET_ADMIN_SUB_CATEGORIES_REQUEST';
export const GET_ADMIN_SUB_CATEGORIES_SUCCESS = 'GET_ADMIN_SUB_CATEGORIES_SUCCESS';
export const GET_ADMIN_SUB_CATEGORIES_FAIL = 'GET_ADMIN_SUB_CATEGORIES_FAIL';

export function getAdminSubCategoriesRequest(payload = {}) {
  return {
    type: GET_ADMIN_SUB_CATEGORIES_REQUEST,
    payload,
  };
}

export const CREATE_ADMIN_CATEGORY = 'CREATE_ADMIN_CATEGORY';
export const UPDATE_ADMIN_CATEGORY = 'UPDATE_ADMIN_CATEGORY';
export const DELETE_ADMIN_CATEGORY = 'DELETE_ADMIN_CATEGORY';
export function createAdminCategory(payload = {}) {
  return {
    type: CREATE_ADMIN_CATEGORY,
    payload,
  };
}
export function updateAdminCategory(payload = {}) {
  return {
    type: UPDATE_ADMIN_CATEGORY,
    payload,
  };
}
export function deleteAdminCategory(payload = {}) {
  return {
    type: DELETE_ADMIN_CATEGORY,
    payload,
  };
}

export const CREATE_ADMIN_SUB_CATEGORY = 'CREATE_ADMIN_SUB_CATEGORY';
export const UPDATE_ADMIN_SUB_CATEGORY = 'UPDATE_ADMIN_SUB_CATEGORY';
export const DELETE_ADMIN_SUB_CATEGORY = 'DELETE_ADMIN_SUB_CATEGORY';
export function createAdminSubCategory(payload = {}) {
  return {
    type: CREATE_ADMIN_SUB_CATEGORY,
    payload,
  };
}
export function updateAdminSubCategory(payload = {}) {
  return {
    type: UPDATE_ADMIN_SUB_CATEGORY,
    payload,
  };
}
export function deleteAdminSubCategory(payload = {}) {
  return {
    type: DELETE_ADMIN_SUB_CATEGORY,
    payload,
  };
}
