import React from 'react';
import classNames from 'classnames';
import styles from '../css/index.module.scss';
import DotsIcon from '../../../assets/formIcons/dots.svg';
import useComponentVisible from '../../../helpers/hooks/useComponentVisible';
import BlockIcon from '../../../assets/formIcons/block.svg';
import EditIcon from '../../../assets/formIcons/edit.svg';
import TableCell from '../../../components/TableCell';

function CategoryListItem({
  item, textToShow, selected, clickable, onItemClick, onRenameClick, onDeleteClick,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handlePopUpClick = (fn) => (...args) => {
    setIsComponentVisible(false);
    fn(...args);
  };

  return (
    <div className={classNames(styles.listItem, { [styles.listItemSelected]: selected, [styles.listItemClickable]: clickable })} onClick={onItemClick}>
      <p className={styles.listItemText}>{textToShow}</p>
      <TableCell
        isIcon
        onClick={(ev) => {
          ev.stopPropagation();
          setIsComponentVisible(true);
        }}
      >
        <img src={DotsIcon} alt=":" />
      </TableCell>

      <div
        ref={ref}
        className={`table_popup ${isComponentVisible && 'visible'}`}
        onClick={(ev) => ev.stopPropagation()}
      >
        <div className="table_popup_item" onClick={handlePopUpClick(onRenameClick)}>
          <img src={EditIcon} alt="" />
          <p>Переименовать</p>
        </div>
        <div className="table_popup_item" onClick={handlePopUpClick(onDeleteClick)}>
          <img src={BlockIcon} alt="" />
          <p>Удалить</p>
        </div>
      </div>
    </div>
  );
}

export default CategoryListItem;
