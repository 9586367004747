import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './css/index.module.scss';
import PageTitle from '../../components/PageTitle';
import {
  getAdminCategoriesRequest,
  getAdminSubCategoriesRequest,
  updateAdminCategory,
} from '../../store/actions/categories';
import MyLoading from '../../components/MyLoading';
import MyButton from '../../components/MyButton';
import CategoryListItem from './components/CategoryListItem';
import Api from '../../Api';
import sendRequest from '../../helpers/sendRequest';
import CategoriesModal from './components/CategoriesModal';
import { CATEGORIES_MODAL_STATES } from './constants';

function Categories() {
  const iconInputRef = useRef();
  const iconPressedInputRef = useRef();
  const dispatch = useDispatch();
  const {
    categories, subCategories, gettingLoading, error,
  } = useSelector((store) => store.categories);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [modalState, setModalState] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [iconLoading, setIconLoading] = useState(false);
  const [iconLoading2, setIconLoading2] = useState(false);
  const [categoryToInteract, setCategoryToInteract] = useState(null);
  const [subCategoryToInteract, setSubCategoryToInteract] = useState(null);

  const onCategoryClick = (item) => {
    if (item._id === selectedCategory?._id) return;

    setSelectedCategory(item);
    setSelectedFile(null);
    dispatch(getAdminSubCategoriesRequest({ category_id: item._id }));
  };

  const onAddCategoryClick = () => {
    setModalState(CATEGORIES_MODAL_STATES.newCategory);
  };

  const onRenameCategoryClick = (item) => {
    setCategoryToInteract(item);
    setModalState(CATEGORIES_MODAL_STATES.renameCategory);
  };
  const onDeleteCategoryClick = (item) => {
    setCategoryToInteract(item);
    setModalState(CATEGORIES_MODAL_STATES.deleteCategory);
  };

  const onAddSubCategoryClick = () => {
    setModalState(CATEGORIES_MODAL_STATES.newSubCategory);
  };
  const onRenameSubCategoryClick = (item) => {
    setSubCategoryToInteract(item);
    setModalState(CATEGORIES_MODAL_STATES.renameSubCategory);
  };
  const onDeleteSubCategoryClick = (item) => {
    setSubCategoryToInteract(item);
    setModalState(CATEGORIES_MODAL_STATES.deleteSubCategory);
  };

  const onSelectIconClick = () => {
    if (iconInputRef.current?.click) {
      iconInputRef.current.click();
    }
  };

  const onSelectIconPressedClick = () => {
    if (iconPressedInputRef.current?.click) {
      iconPressedInputRef.current.click();
    }
  };

  const handleIconInput = (ev) => {
    if (ev.target?.files?.length) {
      setSelectedFile(ev.target.files[0]);
    }
  };

  const handleIconPressedInput = (ev) => {
    if (ev.target?.files?.length) {
      setSelectedFile2(ev.target.files[0]);
    }
  };

  const onSaveIconClick = async () => {
    if (!selectedCategory || !selectedFile) return;

    const formData = new FormData();
    formData.append('image', selectedFile);

    const data = await sendRequest({
      request: Api.editIconAdminCategory,
      payload: { id: selectedCategory._id, formData },
      setLoading: setIconLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateAdminCategory(data.category));
      setSelectedFile(null);
      setSelectedCategory(data.category);
    }
  };

  const onSaveIconPressedClick = async () => {
    if (!selectedCategory || !selectedFile2) return;

    const formData = new FormData();
    formData.append('image', selectedFile2);

    const data = await sendRequest({
      request: Api.editIconAdminCategory,
      payload: { id: selectedCategory._id, formData, params: { pressed_icon: true } },
      setLoading: setIconLoading2,
    });

    if (data?.status === 'ok') {
      dispatch(updateAdminCategory(data.category));
      setSelectedFile2(null);
      setSelectedCategory(data.category);
    }
  };

  useEffect(() => {
    if (!categories?.length) {
      dispatch(getAdminCategoriesRequest());
    }
  }, []);

  return (
    <div>
      <PageTitle
        text="Категории организаций"
        RightComponent={<MyLoading height={30} width={30} strokeWidth={4} visible={gettingLoading} />}
      />
      <div className={styles.main}>
        <div className={styles.block}>
          <p className={styles.blockTitle}>Категории</p>
          <MyButton
            secondary
            text="Добавить"
            onClick={onAddCategoryClick}
            className={styles.blockButton}
          />
          <div className={styles.list}>
            {categories?.length ? (
              categories.map((item) => (
                <CategoryListItem
                  key={item._id}
                  item={item}
                  textToShow={item.name}
                  clickable
                  selected={item._id === selectedCategory?._id}
                  onItemClick={() => onCategoryClick(item)}
                  onRenameClick={() => onRenameCategoryClick(item)}
                  onDeleteClick={() => onDeleteCategoryClick(item)}
                />
              ))
            ) : <p className={styles.listEmptyText}>Пусто.</p>}
          </div>
        </div>
        {selectedCategory?._id ? (
          <div className={styles.block}>
            <p className={styles.blockTitle}>Подкатегории</p>
            <MyButton
              secondary
              text="Добавить"
              onClick={onAddSubCategoryClick}
              className={styles.blockButton}
            />
            <div className={styles.list}>
              {subCategories?.length ? (
                subCategories.map((item) => (
                  <CategoryListItem
                    key={item._id}
                    item={item}
                    textToShow={`${item.name} (${item.organizations_count || 0})`}
                    clickable={false}
                    onRenameClick={() => onRenameSubCategoryClick(item)}
                    onDeleteClick={() => onDeleteSubCategoryClick(item)}
                  />
                ))
              ) : <p className={styles.listEmptyText}>Пусто.</p>}
            </div>
          </div>
        ) : null}
        {selectedCategory?._id ? (
          <div className={classNames(styles.block, styles.blockWithScroll)}>
            <p className={styles.blockTitle}>Иконка (основная)</p>
            <MyButton
              secondary
              text="Выбрать из компьютера"
              onClick={onSelectIconClick}
              className={styles.blockButton}
            />
            <p className={styles.blockInfo}>
              Обязательно надо загружать файл с расширением .png (серый фон будет добавлен программно в приложении)
            </p>
            <div className={styles.blockImageRow}>
              <p className={styles.blockImageText}>Текущая иконка:</p>
              {selectedCategory.icon_path ? (
                <div className={styles.blockImageDiv} style={{ backgroundColor: '#E8EBF9' }}>
                  <img src={Api.url + selectedCategory.icon_path} alt="Current Icon" />
                </div>
              ) : <p>не выбрано</p>}
            </div>
            {selectedFile && (
            <>
              <div className={styles.blockImageRow}>
                <p className={styles.blockImageText}>Выбранный файл:</p>
                <div className={styles.blockImageDiv} style={{ backgroundColor: '#E8EBF9' }}>
                  <img src={URL.createObjectURL(selectedFile)} alt="Selected Icon" />
                </div>
              </div>

              <MyButton
                text="Сохранить иконку"
                onClick={onSaveIconClick}
                className={styles.blockButton}
                loading={iconLoading}
              />
            </>
            )}
            <div style={{ height: '60px' }} />
            <hr />
            <div style={{ height: '60px' }} />
            <p className={styles.blockTitle}>Иконка при нажатии</p>
            <MyButton
              secondary
              text="Выбрать из компьютера"
              onClick={onSelectIconPressedClick}
              className={styles.blockButton}
            />
            <p className={styles.blockInfo}>
              Обязательно надо загружать файл с расширением .png (синий фон будет добавлен программно в приложении)
            </p>
            <div className={styles.blockImageRow}>
              <p className={styles.blockImageText}>Текущая иконка:</p>
              {selectedCategory.icon_pressed_path ? (
                <div className={styles.blockImageDiv}>
                  <img src={Api.url + selectedCategory.icon_pressed_path} alt="Current Icon" />
                </div>
              ) : <p>не выбрано</p>}
            </div>
            {selectedFile2 && (
              <>
                <div className={styles.blockImageRow}>
                  <p className={styles.blockImageText}>Выбранный файл:</p>
                  <div className={styles.blockImageDiv}>
                    <img src={URL.createObjectURL(selectedFile2)} alt="Selected Icon" />
                  </div>
                </div>

                <MyButton
                  text="Сохранить иконку"
                  onClick={onSaveIconPressedClick}
                  className={styles.blockButton}
                  loading={iconLoading2}
                />
              </>
            )}

            <input
              type="file"
              hidden
              ref={iconInputRef}
              accept="image/png"
              multiple={false}
              onChange={handleIconInput}
            />
            <input
              type="file"
              hidden
              ref={iconPressedInputRef}
              accept="image/png"
              multiple={false}
              onChange={handleIconPressedInput}
            />
          </div>
        ) : null}
      </div>

      <CategoriesModal
        modalState={modalState}
        setModalState={setModalState}
        categoryToInteract={categoryToInteract}
        subCategoryToInteract={subCategoryToInteract}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </div>
  );
}

export default Categories;
