import axios from 'axios';
import LocalStorageServices from './helpers/LocalStorageServices';
import { SERVER_URL } from './serverUrl';

const api = axios.create({
  baseURL: SERVER_URL,
  timeout: 60 * 1000, // 1 minute
});

api.interceptors.request.use(
  (params) => {
    const token = LocalStorageServices.getToken();
    if (token) {
      params.headers.Authorization = `Bearer ${token}`;
    }
    return params;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error?.response?.status === 401) {
      LocalStorageServices.removeToken();
    }
    return Promise.reject(error);
  },
);

class Api {
  static url = SERVER_URL;

  /* Account start */

  static login(data) {
    return api.post('/api/admins/login', data);
  }

  static getProfile(data) {
    return api.get('/api/admins/profile', data);
  }

  /* Account end */

  /* Organizations start */

  static getOrganizations(params) {
    return api.get('/api/organizations', { params });
  }

  static getOrganization({ id }) {
    return api.get(`/api/organizations/${id}?should_add_clicks=false`);
  }

  static blockOrganization(data) {
    return api.put('/api/admins/organizations/block', data);
  }

  static changeOrganizationOwner(data) {
    return api.put('/api/admins/organizations/change-owner', data);
  }

  static createOrganization(formData) {
    return api.post('/api/admins/organizations/create', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static editOrganization({ id, payload }) {
    return api.patch(`/api/admins/organizations/edit/${id}`, payload);
  }

  static addOrganizationPhotos({ id, formData }) {
    return api.post(`/api/admins/organizations/${id}/photos/add`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static removeOrganizationPhotos({ id, payload }) {
    return api.post(`/api/admins/organizations/${id}/photos/remove`, payload);
  }

  /* Organizations end */

  /* Users start */
  static getUsers(data) {
    return api.get('/api/admins/users', {
      params: data,
    });
  }

  static blockUser(data) {
    return api.put('/api/admins/users/block', data);
  }

  /* Users end */

  /* Admins start */

  static getAdmins(data) {
    return api.get('/api/root/admins', {
      params: data,
    });
  }

  static editAdmin({ id, data }) {
    return api.put(`/api/root/admins/${id}`, data);
  }

  static addAdmin(data) {
    return api.post('/api/root/admins', data);
  }

  static deleteAdmin(id) {
    return api.delete(`/api/root/admins/${id}`);
  }

  /* Admins end */

  /* Static data start */

  static getCountries() {
    return api.get('/api/static-data/countries?sort=creation');
  }

  static getLanguages() {
    return api.get('/api/static-data/languages');
  }

  static getCategories() {
    return api.get('/api/static-data/categories-all');
  }

  static getCities(params) {
    return api.get('/api/static-data/cities', { params });
  }

  static getInfoTable(params) {
    return api.get('/api/static-data/info-table', { params });
  }

  /* Static data end */

  /* Admins start */

  static addCountry(formData) {
    return api.post('/api/admins/countries', formData);
  }

  static editCountry({ id, formData }) {
    return api.put(`/api/admins/countries/${id}`, formData);
  }

  static deleteCountry(id) {
    return api.delete(`/api/admins/countries/${id}`);
  }

  static addCity(data) {
    return api.post('/api/admins/cities', data);
  }

  static editCities(data) {
    return api.put('/api/admins/cities', data);
  }

  static deleteCity(id) {
    return api.delete(`/api/admins/cities/${id}`);
  }

  /* Admins end */

  /* Categories start */

  static getAdminCategories(params) {
    return api.get('/api/admins/categories', { params });
  }

  static createAdminCategory(body) {
    return api.post('/api/admins/categories', body);
  }

  static renameAdminCategory({ id, ...body }) {
    return api.put(`/api/admins/categories/${id}/name`, body);
  }

  static editIconAdminCategory({ id, formData, params }) {
    return api.put(`/api/admins/categories/${id}/icon`, formData, { params });
  }

  static deleteAdminCategory({ id }) {
    return api.delete(`/api/admins/categories/${id}`);
  }

  static getAdminSubCategories(params) {
    return api.get('/api/admins/sub-categories', { params });
  }

  static createAdminSubCategory(body) {
    return api.post('/api/admins/sub-categories', body);
  }

  static renameAdminSubCategory({ id, ...body }) {
    return api.put(`/api/admins/sub-categories/${id}/name`, body);
  }

  static deleteAdminSubCategory({ id }) {
    return api.delete(`/api/admins/sub-categories/${id}`);
  }

  /* Categories end */

  /* Gallery start */

  static getGalleryPhotos(params) {
    return api.get('/api/admins/gallery', { params });
  }

  static addPhotosToGallery(formData) {
    return api.post('/api/admins/gallery', formData);
  }

  static deletePhotosToGallery({ id, params }) {
    return api.delete(`/api/admins/gallery/${id}`, { params });
  }

  /* Gallery end */

  /* Infos start */

  static editInfoItem(data) {
    return api.put('/api/admins/infos', data);
  }

  /* Infos end */

  /* Complaints start */

  static getComplaints({ params }) {
    return api.get('/api/admins/complaints', { params });
  }

  static getComplaintsCount({ params }) {
    return api.get('/api/admins/complaints/count', { params });
  }

  static answerComplain(data) {
    return api.put('/api/admins/complaints', data);
  }

  /* Complaints end */
}

export default Api;
