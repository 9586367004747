import {
  CREATE_ADMIN_CATEGORY, CREATE_ADMIN_SUB_CATEGORY, DELETE_ADMIN_CATEGORY, DELETE_ADMIN_SUB_CATEGORY,
  GET_ADMIN_CATEGORIES_FAIL,
  GET_ADMIN_CATEGORIES_REQUEST,
  GET_ADMIN_CATEGORIES_SUCCESS,
  GET_ADMIN_SUB_CATEGORIES_FAIL,
  GET_ADMIN_SUB_CATEGORIES_REQUEST,
  GET_ADMIN_SUB_CATEGORIES_SUCCESS, UPDATE_ADMIN_CATEGORY, UPDATE_ADMIN_SUB_CATEGORY,
} from '../actions/categories';

const initialState = {
  categories: [],
  subCategories: [],
  gettingLoading: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_CATEGORIES_REQUEST: {
      return {
        ...state,
        gettingLoading: true,
      };
    }
    case GET_ADMIN_CATEGORIES_SUCCESS: {
      return {
        ...state,
        gettingLoading: false,
        categories: action.payload,
      };
    }
    case GET_ADMIN_CATEGORIES_FAIL: {
      return {
        ...state,
        gettingLoading: false,
        error: action.payload,
      };
    }

    case GET_ADMIN_SUB_CATEGORIES_REQUEST: {
      return {
        ...state,
        gettingLoading: true,
      };
    }
    case GET_ADMIN_SUB_CATEGORIES_SUCCESS: {
      return {
        ...state,
        gettingLoading: false,
        subCategories: action.payload,
      };
    }
    case GET_ADMIN_SUB_CATEGORIES_FAIL: {
      return {
        ...state,
        gettingLoading: false,
        error: action.payload,
      };
    }

    case CREATE_ADMIN_CATEGORY: {
      return {
        ...state,
        categories: [action.payload, ...state.categories],
      };
    }
    case UPDATE_ADMIN_CATEGORY: {
      return {
        ...state,
        categories: state.categories.map((i) => (i._id === action.payload?._id ? action.payload : i)),
      };
    }
    case DELETE_ADMIN_CATEGORY: {
      return {
        ...state,
        categories: state.categories.filter((i) => i._id !== action.payload?._id),
      };
    }
    case CREATE_ADMIN_SUB_CATEGORY: {
      return {
        ...state,
        subCategories: [action.payload, ...state.subCategories],
      };
    }
    case UPDATE_ADMIN_SUB_CATEGORY: {
      return {
        ...state,
        subCategories: state.subCategories.map((i) => (i._id === action.payload?._id ? action.payload : i)),
      };
    }
    case DELETE_ADMIN_SUB_CATEGORY: {
      return {
        ...state,
        subCategories: state.subCategories.filter((i) => i._id !== action.payload?._id),
      };
    }

    default: {
      return state;
    }
  }
}
